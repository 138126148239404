var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"process-box"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("label.processsetting.process.explain"))+" ")]),_c('div',{staticClass:"btn-box"},[_c('el-button',{staticClass:"new-build",attrs:{"type":"primary"},on:{"click":_vm.newBulidProcess}},[_vm._v(" "+_vm._s(_vm.$t("label.processsetting.new.process"))+" ")])],1),_c('div',{staticClass:"table-box"},[_c('div',{staticClass:"table-box-table"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"row-style":{ height: '40px' },"cell-style":{ padding: '0px', color: '#333', fontSize: '14px' },"header-cell-style":{
            background: '#F5F5F5',
            color: '#333',
            height: '40px',
            padding: '0',
            'border-right': '1px solid #dcdcdc',
          }}},[_c('el-table-column',{attrs:{"prop":"name","label":_vm.$t('label.import.index.obj')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"im-event-item",on:{"click":function($event){return _vm.handleImEvent('edit', scope.row)}}},[_vm._v(" "+_vm._s(scope.row.labelname)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"use","label":_vm.$t('label.emailtocloudcc.button.enable')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-checkbox',{attrs:{"disabled":""},model:{value:(scope.row.isusing),callback:function ($$v) {_vm.$set(scope.row, "isusing", $$v)},expression:"scope.row.isusing"}})]}}])}),_c('el-table-column',{attrs:{"prop":"last","label":_vm.$t('label.lastmodifyby')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.lastmodifybyccname)+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('label.import.index.operation'),"width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticClass:"im-event-item",attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleImEvent('edit', scope.row)}}},[_vm._v(" "+_vm._s(_vm.$t("label.tabpage.editz")))]),(!scope.row.isusing)?_c('el-button',{staticClass:"im-event-item",attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleImEvent('enable', scope.row)}}},[_vm._v(_vm._s(_vm.$t("label.emailtocloudcc.button.enable")))]):_vm._e(),(scope.row.isusing)?_c('el-button',{staticClass:"im-event-item",attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleImEvent('disable', scope.row)}}},[_vm._v(_vm._s(_vm.$t("label.emailsync.status.disabel")))]):_vm._e(),(
                  !scope.row.isusing &&
                  scope.row.objid != 'lead' &&
                  scope.row.objid != 'opportunity'
                )?_c('el-button',{staticClass:"im-event-item",attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleImEvent('delete', scope.row)}}},[_vm._v(_vm._s(_vm.$t("label.chatter.delete")))]):_vm._e()]}}])})],1)],1)]),_c('el-dialog',{attrs:{"title":_vm.$t('label.emailtocloudcc.tip'),"visible":_vm.deleteTitle,"width":"30%","before-close":_vm.deleteHandleClose},on:{"update:visible":function($event){_vm.deleteTitle=$event}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("label.processsetting.delete.process"))+" ")]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.deleteHandleClose}},[_vm._v(_vm._s(_vm.$t("label.emailsync.button.cancel"))+" ")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.deleteHandleShure}},[_vm._v(_vm._s(_vm.$t("label.tabpage.ok")))])],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }