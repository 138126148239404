<template>
  <div>
    <div class="process-box">
      <div class="title">
        {{ $t("label.processsetting.process.explain") }}
        <!-- 通过设置与使用进程，使用户专注于最重要的事项，在恰当的时间为用户展示关键字与小贴士，帮助用户完成流程。 -->
      </div>
      <div class="btn-box">
        <el-button type="primary" @click="newBulidProcess" class="new-build">
          <!-- 新建进程 -->
          {{ $t("label.processsetting.new.process") }}
        </el-button>
      </div>
      <div class="table-box">
        <div class="table-box-table">
          <el-table
            :data="tableData"
            :row-style="{ height: '40px' }"
            :cell-style="{ padding: '0px', color: '#333', fontSize: '14px' }"
            :header-cell-style="{
              background: '#F5F5F5',
              color: '#333',
              height: '40px',
              padding: '0',
              'border-right': '1px solid #dcdcdc',
            }"
            style="width: 100%"
          >
            <!-- 对象，启用，最后修改人，操作 -->
            <el-table-column prop="name" :label="$t('label.import.index.obj')">
              <template slot-scope="scope">
                <div
                  class="im-event-item"
                  @click="handleImEvent('edit', scope.row)"
                >
                  {{ scope.row.labelname }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="use"
              :label="$t('label.emailtocloudcc.button.enable')"
            >
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.isusing" disabled></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column prop="last" :label="$t('label.lastmodifyby')">
              <template slot-scope="scope">
                {{ scope.row.lastmodifybyccname }}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('label.import.index.operation')"
              width="180"
            >
              <template slot-scope="scope">
                <el-button
                  @click="handleImEvent('edit', scope.row)"
                  type="text"
                  size="small"
                  class="im-event-item"
                >
                  {{ $t("label.tabpage.editz") }}</el-button
                >
                <el-button
                  v-if="!scope.row.isusing"
                  @click="handleImEvent('enable', scope.row)"
                  type="text"
                  size="small"
                  class="im-event-item"
                  >{{ $t("label.emailtocloudcc.button.enable") }}</el-button
                >
                <el-button
                  v-if="scope.row.isusing"
                  @click="handleImEvent('disable', scope.row)"
                  type="text"
                  size="small"
                  class="im-event-item"
                  >{{ $t("label.emailsync.status.disabel") }}</el-button
                >
                <el-button
                  v-if="
                    !scope.row.isusing &&
                    scope.row.objid != 'lead' &&
                    scope.row.objid != 'opportunity'
                  "
                  @click="handleImEvent('delete', scope.row)"
                  type="text"
                  size="small"
                  class="im-event-item"
                  >{{ $t("label.chatter.delete") }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <el-dialog
        :title="$t('label.emailtocloudcc.tip')"
        :visible.sync="deleteTitle"
        width="30%"
        :before-close="deleteHandleClose"
      >
        <span>
          <!-- 确认删除该进程？ -->
          {{ $t("label.processsetting.delete.process") }}
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="deleteHandleClose"
            >{{ $t("label.emailsync.button.cancel") }}
          </el-button>
          <el-button type="primary" @click="deleteHandleShure">{{
            $t("label.tabpage.ok")
          }}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
  <script>
import {
  getProgressSetupList,
  deleteProgress,
  saveProgressSetup,
} from "@/views/systemSettings/systemSettingsApi.js";

export default {
  data() {
    return {
      tableData: [], //进程列表
      deleteTitle: false, //删除进程弹框
      deleteId: "", //要删除的进程id
    };
  },
  mounted() {
    this.getProgressSetupList();
  },
  methods: {
    // 启用或禁用某个进程
    async saveProgressSetup(data, flag) {
      let param = {
        id: data.id, //否	string	进度条id
        objid: data.objid, //是	string	对象id
        fieldId: data.fieldId, //是	string	字段id
        isusing: flag, //是否启用进度条，true 或 false
      };
      let res = await saveProgressSetup(param);
      if (res.result) {
        this.getProgressSetupList();
        if (flag) {
          this.$message.success(
            this.$i18n.t("label.processsetting.process.enabled.successfully")
          ); //启用进程成功！
        } else {
          this.$message.success(
            this.$i18n.t("label.processsetting.disable.process.successfully")
          ); //禁用进程成功！
        }
      } else {
        if (flag) {
          this.$message.warning(
            this.$i18n.t("label.processsetting.failed.enable.process")
          ); //启用进程失败！
        } else {
          this.$message.warning(
            this.$i18n.t("label.processsetting.disable.process.failed")
          ); //禁用进程失败！
        }
      }
    },
    // 获取进程列表
    async getProgressSetupList() {
      let res = await getProgressSetupList();
      if (res.result) {
        res.data.map((item) => {
          if (item.isusing == "true") {
            item.isusing = true;
          } else if (item.isusing == "false") {
            item.isusing = false;
          }
        });
        this.tableData = res.data;
        this.deleteId = "";
      }
    },
    // 操作：编辑，删除，启用，禁用
    handleImEvent(operation, data) {
      if (operation == "edit") {
        // 路由跳转需要携带的参数？
        // id labelname fieldlabel fieldId objid
        this.$router.push({
          path: "newBuildProcess",
          query: {
            id: data.id,
            labelname: data.labelname,
            fieldlabel: data.fieldlabel,
            fieldId: data.fieldId,
            objid: data.objid
          },
        });
      } else if (operation == "delete") {
        this.deleteTitle = true;
        this.deleteId = data.id;
      } else if (operation == "enable") {
        this.saveProgressSetup(data, true);
      } else if (operation == "disable") {
        this.saveProgressSetup(data, false);
      }
    },
    //删除进度条
    async deleteProgress() {
      let param = {
        id: this.deleteId,
      };
      let res = await deleteProgress(param);
      if (res.result) {
        this.$message.success(
          this.$i18n.t("label.processsetting.delete.process.succeeded")
        );
        //  this.$message.success("删除进程成功！");
        this.getProgressSetupList();
      } else {
        this.$message.warning(
          this.$i18n.t("label.processsetting.process.enabled.failed")
        );
        // this.$message.warning("删除进程失败！");
      }
    },
    // 取消删除进程弹框
    deleteHandleClose() {
      this.deleteTitle = false;
    },
    // 确定删除进程弹框
    deleteHandleShure() {
      this.deleteTitle = false;
      this.deleteProgress();
    },
    // 新建进程
    newBulidProcess() {
      this.$router.push({
        path: "newBuildProcess",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #006dcc !important;
}
.process-box {
  padding: 30px 10px;
  .title {
    font-size: 14px;
    color: #080707;
    letter-spacing: 0;
  }
  .btn-box {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    .new-build {
      background: #006dcc;
      border-color: #006dcc;
      width: 176px;
      height: 45px;
    }
  }
  .table-box {
    margin-top: 40px;
    margin: 40px 10% 0;
    &-table {
      border: 1px solid #dcdcdc;
      border-radius: 3px;
      .im-event-item {
        color: #006dcc;
        cursor: pointer;
      }
    }
    .el-table td {
      border-bottom: 1px solid #dedcda;
    }
  }
}
</style>